.bona-logo {
    height: auto;
    width: auto;
}

@media screen and (max-width: 1000px) {
    .bona-logo {
        height: 100%;
        width: 100%;
    }  
}

@media screen and (max-width: 400px) {
    .bona-logo {
        margin-top: 15px;
        height: 100%;
        width: 100%;
    }
    main {
        height: 100%;
    }
    ul {
        display: none;
    }
}
main {
    margin-bottom: 0px;
    min-height: 100vh;
}


