main {
    margin-bottom: 0px;
    min-height: 100vh;
}

.Card-Space {
    top: 10vh;
    width: 33%;
}

.signup-width {
    width: 66%;
    top: 10vh;
}

@media screen and (max-width: 1000px) {
    .Card-Space {
        width: 66%;
    }
    
    .signup-width {
        width: 88%;
    }
}

@media screen and (max-width: 400px) {
    .Card-Space {
        top: 2vh;
        width: 100%;
    }
    .signup-width {
        width: 100%;
    }
}